import { render, staticRenderFns } from "./roamPage.vue?vue&type=template&id=415c24ca&scoped=true"
import script from "./roamPage.vue?vue&type=script&lang=js"
export * from "./roamPage.vue?vue&type=script&lang=js"
import style0 from "./roamPage.vue?vue&type=style&index=0&id=415c24ca&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415c24ca",
  null
  
)

export default component.exports