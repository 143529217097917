<template>
    <div class="layout-config h-100 p-20">
      <h3 class="b-b-1 m-b-10">
        {{ $route.query.id ? "编辑" : "新增" }}研学漫游
      </h3>
      <Form
        ref="form"
        :model="formItem"
        :rules="rules"
        :label-width="140"
        label-colon
      >
        <Row :gutter="10">
          <Col span="12">
            <FormItem label="研学漫游名称" prop="name">
              <Input
                v-model="formItem.name"
                placeholder="请输入研学漫游名称"
                clearable
              />
            </FormItem>
            <FormItem label="研学漫游类型" prop="type">
                <SelectDict v-model="formItem.type" :dict="dict.roamtype" placeholder="请选择漫游类型" clearable />
            </FormItem>
            <FormItem label="研学点漫游" v-if="formItem.type === 'point'">
                <SelectPoint v-model="formItem.content" placeholder="请选择研学漫游所需的研学点" isArray />
            </FormItem>
            <FormItem label="研学路线漫游" v-if="formItem.type === 'line'">
                <SelectLine v-model="formItem.content" placeholder="请选择一条研学线路进行漫游" isArray :multiple="false" label-in-value />
            </FormItem>
            <FormItem label="研学全景漫游" v-if="formItem.type === 'panorama'">
              <SelectPanorama v-model="formItem.content"  placeholder="请选择研学点全景图" isArray :multiple="true" />
            </FormItem>
          </Col>
        </Row>
  
        <FormItem>
          <Button type="success" @click="saveData">提交</Button>
          <Button type="default" class="m-l-5" @click="goBack">返回</Button>
        </FormItem>
      </Form>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { util } from "@/libs";
  import { dict } from '@/config'
  import { getRoamById, addRoam, updateRoam } from "@/libs/api/roam";
  export default {
      data() {
        return {
            dict,
            formItem: { type: 'point' },
            rules: {
                name: { required: true, message: "请输入研学漫游名称" },
                type: { required: true, message: "请选择研学漫游类型" },
            }
        };
      },
      computed: {
          ...mapState({
              userId: (state) => state.user.userId,
          }),
      },
      methods: {
          // 保存数据
          saveData() {
              this.$refs.form.validate((valid) => {
                  if (valid) {
                      let contentArray = this.formItem.content
                      this.formItem.content = typeof contentArray == 'string' ? contentArray.split(',') : contentArray
                      if (!this.formItem.userId)
                          this.formItem.userId = this.userId;
                      if (this.formItem.id) {
                          // 编辑漫游
                          updateRoam(this.formItem).then((res) => {
                              this.successResult(res, "编辑成功!");
                          });
                      }
                      else {
                          //   添加漫游
                          addRoam(this.formItem).then((res) => {
                              this.successResult(res, "添加成功!");
                          });
                      }
                  }
              });
          },
          // 新增修改成功回调
          successResult(res, descMsg) {
              if (res.code == "HA0200") {
                  this.$Message.success(descMsg || res.msg);
                  this.goBack();
              }
              else {
                  this.$Message.error(res.msg);
              }
          },
          // 返回列表
          goBack() {
              this.$router.push("/config/dataManage/roam");
          },
      },
      async created() {
          const { id } = this.$route.query;
          if (id) {
            getRoamById({ id }).then(({ code, data }) => {
                  if (code == "HA0200") {
                      let content_info
                      if(data.type === 'line') {
                        content_info = data.content.map(item => JSON.parse(item).researchLineId)
                      }else {
                        content_info = data.content.map(item => JSON.parse(item).researchPointId)
                      }
                      data.content = content_info
                      this.formItem = data;
                      this.formItem.type = data.type
                  }
              });
          }
          else {
              this.formItem = {
                  type: 'point'
              };
          }
      },
  };
  </script>
  
  <style lang="less" scoped>
  .layout-config {
    overflow-y: scroll;
  }
  </style>
  